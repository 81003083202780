<template>
  <div>
    <!-- <div ref="bbb" v-html="aaa"></div> -->
    <iframe width="100%" height="500px" src="static/html4DataModels/888.html" frameborder="0"></iframe>
  </div>
</template>

<script>
// import baseUrl from '../../../util/config'
let baseUrl = 'http://localhost:8081'

export default {

  data() {
    return {
      fileName: "888.html",
      aaa:''
    };
  },


  mounted() {
    this.loadFile();
  },

  methods: {
    loadFile() {
      this.$axios({
        method: "get",
        url: "../../static/html4DataModels/" + this.fileName
      })
        .then(result => {
          this.aaa = result.data;
          console.log(result,'result')
          let _this = this;
          _this.$nextTick(()=>{
            let data = result.data;
            let JSScript = '';
            // 使用正则获取js内容
            result.data.replace(/<script.*?>([\s\S]+?)<\/script>/img,function(_,js){    //正则匹配出script中的内容
                JSScript = js
            });
            var newElement = document.createElement("script")
            newElement.innerHTML = JSScript
            this.$refs.bbb.append(newElement)
          });
        })  
        .catch(error => {
          console.error("error getting file");
          console.error(url);
        });
    }
  },
}
</script>

<style scoped>

</style>